import {
	createRouter,
	createWebHistory
} from 'vue-router'

const routes = [{
		path: '/',
		name: 'login',
		component: function() {
			return import('../views/Login.vue')
		},
		meta: {
			changeToken: false
		}
	},
	{
		path: '/home',
		name: 'home',
		component: function() {
			return import('../views/Home.vue')
		},
		meta: {
			changeToken: true
		},
		children: [{
				path: '/home/subjectlist',
				name: 'subjectlist',
				component: function() {
					return import('../components/subject/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/gradeslist',
				name: 'gradeslist',
				component: function() {
					return import('../components/grades/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/tmsunitlist',
				name: 'tmsunitlist',
				component: function() {
					return import('../components/tmsunit/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/semesterslist',
				name: 'semesterslist',
				component: function() {
					return import('../components/semesters/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/presseslist',
				name: 'presseslist',
				component: function() {
					return import('../components/presses/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/rubrics',
				name: 'rubrics',
				component: function() {
					return import('../components/rubrics/list.vue')
				},
				meta: {
					changeToken: true
				}
			}, {
				path: '/home/rubriccategories',
				name: 'rubriccategories',
				component: function() {
					return import('../components/rubriccategories/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/knowledgecategoreis',
				name: 'knowledgecategoreis',
				component: function() {
					return import('../components/knowledgecategoreis/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/knowledge',
				name: 'knowledge',
				component: function() {
					return import('../components/knowledge/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/rolelist',
				name: 'rolelist',
				component: function() {
					return import('../components/roles/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/questions',
				name: 'questions',
				component: function() {
					return import('../components/questions/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/questioninfo',
				name: 'questioninfo',
				component: function() {
					return import('../components/questions/info.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/questionadd',
				name: 'questionadd',
				component: function() {
					return import('../components/questions/add.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/knowledgeadd',
				name: 'knowledgeadd',
				component: function() {
					return import('../components/knowledge/add.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/cognitivelevel',
				name: 'cognitivelevel',
				component: function() {
					return import('../components/cognitivelevel/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/homework',
				name: 'homework',
				component: function() {
					return import('../components/homework/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/homeworkinfo',
				name: 'homeworkinfo',
				component: function() {
					return import('../components/homework/info.vue')
				},
				meta: {
					changeToken: true
				}
			},
			
			{
				path: '/home/homeworkadd',
				name: 'homeworkadd',
				component: function() {
					return import('../components/homework/add.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/setlist',
				name: 'setlist',
				component: function() {
					return import('../components/setlist/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/setlistadd',
				name: 'setlistadd',
				component: function() {
					return import('../components/setlist/add.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/tenantslist',
				name: 'tenantslist',
				component: function() {
					return import('../components/user/tenantslist.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/teacherlist',
				name: 'teacherlist',
				component: function() {
					return import('../components/user/teacherlist.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/studentlist',
				name: 'studentlist',
				component: function() {
					return import('../components/user/studentlist.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/curriculumlist',
				name: 'curriculumlist',
				component: function() {
					return import('../components/curriculum/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/resourceslist',
				name: 'resourceslist',
				component: function() {
					return import('../components/resources/list.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/curriculuminfo',
				name: 'curriculuminfo',
				component: function() {
					return import('../components/curriculum/info.vue')
				},
				meta: {
					changeToken: true
				}
			},
			{
				path: '/home/menulist',
				name: 'menulist',
				component: function() {
					return import('../components/menu/list.vue')
				},
				meta: {
					changeToken: true
				}
			}
			


		]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})
router.beforeEach((to, from, next) => {
	if (to.matched[0].meta.changeToken) {
		let token = sessionStorage.getItem('token')
		if (token === null || token === '') {
			next('/')
		} else {
			next()
		}
	} else {
		next()
	}
})
export default router
