import { createStore } from 'vuex'

export default createStore({
  state: {
		token: sessionStorage.getItem('token'),
		scheme: sessionStorage.getItem('scheme'),
		defaultid: sessionStorage.getItem('defaultid')
  },
  mutations: {
		setLoginContent(state, data){
			state.token = data.token
			state.scheme = data.scheme
			sessionStorage.setItem('token', data.token)
			sessionStorage.setItem('scheme', data.scheme)
		},
		setdefaultid(state,id){
			state.defaultid = id
			sessionStorage.setItem('defaultid', id)
		}
  },
  actions: {
  },
  modules: {
  }
})
