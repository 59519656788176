<template>
	<el-config-provider :locale="locale">
		<router-view></router-view>
	</el-config-provider>
</template>

<script>
	import {
		ElConfigProvider
	} from 'element-plus'
	import zhCn from 'element-plus/lib/locale/lang/zh-cn'
	export default {
		components: {
			[ElConfigProvider.name]: ElConfigProvider,
		},
		data() {
			return {
				locale: zhCn,
			}
		}
	}
</script>

<style>
	*{
		font-family: 'PingFang SC','Microsoft YaHei','SimHei','Arial','SimSun';
	}
	body {
		padding: 0;
		margin: 0;
	}

	#app {
		width: 100%;
		height: 100vh;
		padding: 0;
		margin: 0;
	}
	.pageview{
		background: #FFFFFF;
		position: absolute;
		top: 10px;
		left: 10px;
		right: 10px;
		bottom: 10px;
		padding: 10px;
		box-sizing: border-box;
	}
	.pageviewtitle{
		border-bottom: 1px solid #cccccc;
		line-height: 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.titletab{
		font-size: 18px;
		font-weight: bold;
	}
	.buttonview{
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.elpagination{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 20px;
	}
	.row{
		padding-bottom: 5px;
	}
	.row-title{
		display: flex;
		align-items: center;
		margin-bottom: 5px;
	}
	.row-title .el-tag{
		margin-left: 10px;
	}
	.titletab{
		display: flex;
		align-items: center;
	}
	.titletab .el-icon{
		margin-right: 20px;
		cursor: pointer;
	}
</style>
