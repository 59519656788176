import { createApp } from 'vue'
import {
	ElIcon,
	ElButton,
	ElInput,
	ElContainer,
	ElAside,
	ElHeader,
	ElMain,
	ElFooter,
	ElMessage,
	ElMenu,
	ElMenuItem,
	ElSubMenu,
	ElPagination,
	ElTable,
	ElTableColumn,
	ElDialog,
	ElTag,
	ElMessageBox,
	ElSelect,
	ElOption,
	ElTree,
	ElScrollbar,
	ElCascader,
	ElLoading ,
	ElUpload,
	ElInputNumber,
	ElRadioGroup,
	ElRadio
} from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'

document.title = '象目派后台管理系统'
const app = createApp(App)

app.use(ElIcon)
app.use(ElButton)
app.use(ElInput)
app.use(ElContainer)
app.use(ElAside)
app.use(ElHeader)
app.use(ElMain)
app.use(ElFooter)
app.use(ElMessage)
app.use(ElMenu)
app.use(ElMenuItem)
app.use(ElSubMenu)
app.use(ElPagination)
app.use(ElTable)
app.use(ElTableColumn)
app.use(ElDialog)
app.use(ElTag)
app.use(ElMessageBox)
app.use(ElSelect)
app.use(ElOption)
app.use(ElTree)
app.use(ElScrollbar)
app.use(ElCascader)
app.use(ElLoading)
app.use(ElUpload)
app.use(ElInputNumber)
app.use(ElRadioGroup)
app.use(ElRadio)
app.use(store).use(router).mount('#app')
